import {
  ErrorNotifier,
  errorDataBuilder,
} from "../../../api/src/utils/error-notifier";

import { logError } from "../../utils/logger";
import config from "../../../config.json";
import vinoFetch from "../../utils/vinoFetch";

const bigCommerceCartIdKey = `BIGCOMMERCE_${process.env.GATSBY_VM_MARKET}_CART_ID`;

export interface CartStorage {
  fetchCartId: () => Promise<string>;
  setCartId: (id: string) => Promise<void>;
  removeCartId: () => Promise<void>;
  setGuestCartEmail?: (email: string) => Promise<void>;
  getGuestCartEmail?: () => Promise<string>;
  removeGuestCartEmail?: () => Promise<void>;
}

export const LocalCartStorage = (
  cartIdKey: string,
  cartEmailKey?: string
): CartStorage => ({
  fetchCartId: async () => {
    return Promise.resolve(localStorage.getItem(cartIdKey));
  },
  setCartId: async (cartId: string) => {
    cartId
      ? localStorage.setItem(cartIdKey, cartId)
      : localStorage.removeItem(cartIdKey);
  },
  removeCartId: async () => {
    localStorage.removeItem(cartIdKey);
  },
  setGuestCartEmail: async (email: string) => {
    email
      ? localStorage.setItem(cartEmailKey, email)
      : localStorage.removeItem(cartEmailKey);
  },
  getGuestCartEmail: async () => {
    return Promise.resolve(localStorage.getItem(cartEmailKey));
  },
  removeGuestCartEmail: async () => {
    localStorage.removeItem(cartEmailKey);
  },
});

export const Auth0CartStorage = (accessToken: string): CartStorage => ({
  fetchCartId: async () => {
    try {
      const {
        data: { data: user },
      } = await vinoFetch.get("/api/get-auth0-user-with-metadata", {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      return user.app_metadata[bigCommerceCartIdKey];
    } catch (error) {
      logError(error.message, { error });
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      return undefined;
    }
  },
  setCartId: async (cartId: string) => {
    const metadata = {
      [bigCommerceCartIdKey]: cartId || null,
    };

    await vinoFetch.get("/api/update-auth0-user-metadata", {
      params: {
        app_metadata: JSON.stringify(metadata),
      },
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  },
  removeCartId: async () => {
    const metadata = {
      [bigCommerceCartIdKey]: null,
    };

    await vinoFetch.get("/api/update-auth0-user-metadata", {
      params: {
        app_metadata: JSON.stringify(metadata),
      },
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  },
});
