import {
  Auth0Provider,
  Auth0ProviderOptions,
  User,
  useAuth0,
} from "@auth0/auth0-react";
import AuthContext, { AuthContextInterface } from "./context";
import {
  ErrorNotifier,
  errorDataBuilder,
} from "../../../api/src/utils/error-notifier";
import {
  IGetAddresses,
  IGetStoredInstruments,
} from "./../../../api/src/services/vino-api-services";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { sampleSize, toUpper } from "lodash";

import { AuthResolver } from "./resolver";
import { CustomerGroup } from "./state";
import { VinoUser } from "./state";
import isBrowser from "../../utils/is-browser";
import { logError } from "../../utils/logger";
import moment from "moment";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import vinoFetch from "../../utils/vinoFetch";

type AuthProviderProps = { children?: ReactNode };

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const {
    error,
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect: auth0Login,
    logout: auth0Logout,
    getAccessTokenSilently,
  } = useAuth0();
  const [authUser, setAuthUser] = useState<VinoUser>(user);
  const [referralCode, setReferralCode] = useState<string>("");
  const [checkingReferralCode, setCheckingReferralCode] = useState<boolean>(
    true
  );

  const location = useLocation();

  const login = useCallback(
    async (appState: { returnTo: string }) => {
      await auth0Login({
        appState,
        fragment: `/auth/login/using-password?returnTo=${appState.returnTo}`,
      });
    },
    [auth0Login]
  );

  const join = useCallback(
    async (appState: { returnTo: string }) => {
      await auth0Login({
        appState,
        fragment: `/auth/join?returnTo=${appState.returnTo}`,
      });
    },
    [auth0Login]
  );

  const logout = useCallback(() => {
    const userId =
      process.env.GATSBY_VM_MARKET +
      "-" +
      (authUser?.["https://www.vinomofo.com/app_metadata"]?.[
        `${process.env.GATSBY_VM_MARKET}_EXTERNAL_ID`
      ] || "");
    auth0Logout({ returnTo: `${location.origin}` });
    if (
      typeof window !== "undefined" &&
      typeof window.analytics !== "undefined"
    ) {
      window.analytics.track("User Logout", {
        category: "Auth",
        userId,
      });
    }
  }, [auth0Logout, authUser]);

  const getAccessToken = useCallback(() => {
    if (isAuthenticated) {
      const ignoreAuthTokenCache =
        localStorage.getItem("refreshAuthToken") === "yes";
      localStorage.setItem("refreshAuthToken", "no");
      return getAccessTokenSilently({
        ignoreCache: ignoreAuthTokenCache,
      });
    }
  }, [isAuthenticated]);

  const fetchCustomerGroup = async (
    customerID?: number | string
  ): Promise<CustomerGroup> => {
    const { data } = await vinoFetch.get("/api/get-customer-group", {
      params: {
        customerID: customerID,
      },
    });
    return data;
  };

  const getAddressesByCustomerId = async ({
    customerID,
  }: IGetAddresses): Promise<any> => {
    const accessToken = await getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const { data } = await vinoFetch.get(`/api/get-addresses-by-customerid`, {
      headers,
      params: {
        customerID,
      },
    });
    return data;
  };

  const getStoredInstrumentsByCustomerId = async ({
    customerID,
    defaultOnly,
  }: IGetStoredInstruments): Promise<any> => {
    const accessToken = await getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const { data } = await vinoFetch.get(
      `/api/get-stored-instruments-by-customerid`,
      {
        headers,
        params: {
          customerID,
          defaultOnly,
        },
      }
    );
    return data;
  };

  const validateEmail = async (email: string): Promise<boolean> => {
    const { exists } = await AuthResolver.validateEmail(email);

    return exists;
  };

  const updateProfile = async (payload: {
    given_name: string;
    family_name: string;
    email: string;
    phone: string;
    birthdate: string;
  }): Promise<{ error?: Error }> => {
    const token = await getAccessTokenSilently();

    return await AuthResolver.updateProfile(payload, token);
  };

  const changeEmailStepTwo = async (payload: {
    email: string;
  }): Promise<{ error?: Error }> => {
    const token = await getAccessTokenSilently();

    return await AuthResolver.changeEmailStepTwo(payload, token);
  };

  const changeEmailStepThree = async (payload: {
    email: string;
    otp: string;
  }): Promise<{ error?: Error }> => {
    const token = await getAccessTokenSilently();

    const response = await AuthResolver.changeEmailStepThree(payload, token);

    if (!error && authUser?.email !== payload.email)
      setAuthUser({
        ...authUser,
        email: payload.email,
      });

    return response;
  };

  const hasSocialLogin = async (email: string): Promise<boolean> => {
    const { isSocial } = await AuthResolver.hasSocialLogin(email);

    return isSocial;
  };

  const setBigCommerceId = async (user: User): Promise<any> => {
    const userAppMeta = user?.["https://www.vinomofo.com/app_metadata"];
    const customer_bigcommerce_id =
      userAppMeta?.[`BIGCOMMERCE_${process.env.GATSBY_VM_MARKET}_CUSTOMER_ID`];
    if (!customer_bigcommerce_id && user?.email) {
      // This is because the auth0 functions are still creating the customer in the background. So get it straight from BigCommerce
      try {
        const bcCustomer = await vinoFetch.get(
          "/api/get-customer-id-by-email",
          {
            params: {
              email: user.email,
              first_name: user.given_name,
              last_name: user.family_name,
            },
          }
        );
        return bcCustomer?.data[0]?.id;
      } catch (error) {
        return 0;
      }
    } else {
      return customer_bigcommerce_id;
    }
  };

  const rePopulateUser = async (user: VinoUser) => {
    if (user) setAuthUser(user);
  };

  const checkReferralCode = async (customer_bigcommerce_id): Promise<any> => {
    try {
      const accessToken = await getAccessToken();
      const headers = { Authorization: `Bearer ${accessToken}` };
      // Check for referral code in customer attribute
      const {
        data: { code: referralCode },
      } = await vinoFetch.get("/api/check-referral-code", {
        headers,
        params: {
          bigcommerceId: customer_bigcommerce_id,
        },
      });
      if (referralCode) {
        setReferralCode(referralCode);
        setCheckingReferralCode(false);
        return referralCode;
      } else {
        setCheckingReferralCode(false);
        return "";
      }
    } catch (error) {
      logError(error.message, { error });
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      setCheckingReferralCode(false);
      return "";
    }
  };

  useEffect(() => {
    if (user && !authUser) {
      const userAppMeta = user?.["https://www.vinomofo.com/app_metadata"];
      const customer_bigcommerce_id =
        userAppMeta?.[
          `BIGCOMMERCE_${process.env.GATSBY_VM_MARKET}_CUSTOMER_ID`
        ];
      const customer_hubspot_id =
        userAppMeta?.[`HUBSPOT_${process.env.GATSBY_VM_MARKET}_CONTACT_ID`];

      const attachCustomerGroupAddressesAndCard = async () => {
        await setBigCommerceId(user);
        const group = await fetchCustomerGroup(customer_bigcommerce_id);
        const addresses = await getAddressesByCustomerId({
          customerID: customer_bigcommerce_id,
        });
        const cards = await getStoredInstrumentsByCustomerId({
          customerID: customer_bigcommerce_id,
          defaultOnly: true,
        });

        const defaultCard = cards.find((card) => card.is_default);
        const newProfile = {
          ...user,
          customer_group: group,
          addresses: addresses,
          stored_instruments: defaultCard ? [defaultCard] : [cards[0]],
        };
        setAuthUser(newProfile);
      };

      if (customer_bigcommerce_id) {
        if (
          !user?.customer_group &&
          !user?.addresses &&
          !user?.stored_instruments
        )
          attachCustomerGroupAddressesAndCard();

        if (!referralCode) checkReferralCode(customer_bigcommerce_id);
      }
    }
  }, [user]);

  const context: AuthContextInterface = {
    error,
    user: authUser ? authUser : user,
    isAuthenticated,
    isLoading,
    join,
    login,
    logout,
    getAccessToken,
    validateEmail,
    updateProfile,
    changeEmailStepTwo,
    changeEmailStepThree,
    rePopulateUser,
    customerReferralCode: referralCode,
    checkingReferralCode,
    checkReferralCode,
    hasSocialLogin,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

const onRedirectCallback = (appState) => {
  if (appState?.returnTo.includes("/guest-checkout")) {
    localStorage.setItem("redirecting", "true");
    const timestamp = Date.now();
    navigate(`/cart?redirection_ts=${timestamp}`);
  } else {
    navigate(appState?.returnTo || "/");
  }
};

const AuthWrapper = ({ children }: AuthProviderProps): JSX.Element => {
  const location = isBrowser() ? useLocation() : { origin: "" };

  const auth0Config: Auth0ProviderOptions = {
    domain: process.env.GATSBY_AUTH0_DOMAIN,
    tenantDomain: process.env.GATSBY_AUTH0_TENANT_DOMAIN,
    clientId: process.env.GATSBY_AUTH0_CLIENT_ID,
    audience: process.env.GATSBY_AUTH0_AUDIENCE,
    redirectUri: `${location.origin}/`,
    cacheLocation: "localstorage",
    useRefreshTokens: true,
    onRedirectCallback,
  };

  return (
    <Auth0Provider {...auth0Config}>
      <AuthProvider>{children}</AuthProvider>
    </Auth0Provider>
  );
};

export default AuthWrapper;
