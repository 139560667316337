import { Cart, CartItem } from "../contexts/cart/types";

import { getShippingPromoStatus } from "../utils/get-shipping-promo-status";

export const itemFromBigCommerceItem = (item): CartItem => {
  return {
    id: item.id,
    productId: item.product_id,
    sku: item.sku,
    name: item.name,
    imageUrl: item.image_url,
    offerId: item.offerId,
    hidden: item?.hidden,
    quantity: item.quantity,
    listPrice: item.list_price,
    salePrice: item.sale_price,
    comparisonPrice: item.comparison_price,
    extendedListPrice: item.extended_list_price,
    extendedSalePrice: item.extended_sale_price,
    extendedComparisonPrice: item.extended_comparison_price,
    discountAmount: item.discount_amount,
    addedByPromotion: item.added_by_promotion,
    slug: item.slug,
    isSoldOut: item.isSoldOut,
    hideRRP: item.hideRRP,
    inventoryStatus: item.inventoryStatus,
    extended_sale_price: item.extended_sale_price,
    currentStock: item.currentStock,
    packSize: item.packSize,
    skuType: item.skuType,
    isWhisky: item?.isWhisky,
  };
};

const lineItemTypes = ["physical_items", "digital_items", "gift_certificates"];
const cartItemsFromBigCommerceLineItems = (lineItems): Array<CartItem> => {
  return lineItemTypes.reduce(
    (acc, keyType) => [
      ...acc,
      ...(lineItems?.[keyType] || []).map(itemFromBigCommerceItem),
    ],
    []
  );
};

export const cartFromBigCommerceCart = ({
  cart,
  isFreeShipping,
  geoLocRegion,
}: {
  cart: any;
  isFreeShipping?: boolean;
  geoLocRegion?: string;
}): Cart => {
  const lineItems = cartItemsFromBigCommerceLineItems(cart?.line_items);
  const cartQuantity = lineItems?.reduce(
    (acc, lineItem) => acc + lineItem.quantity,
    0
  );
  const { cart_amount: cartAmount, coupons, discount_amount, discounts } = cart;
  const totalDiscount = discounts.reduce((b, a) => b + a.discounted_amount, 0);
  const gcProducts = lineItems.filter((gc) => gc.sku.includes("GIFT"));
  const gcProductsQuantity = gcProducts.reduce((b, a) => b + a.quantity, 0);
  const withGiftCard = gcProducts.length > 0;
  const isGiftCardOnly = gcProducts.length === lineItems.length;

  const { shippingTotal, shippingPromoMessage } = getShippingPromoStatus(
    cartAmount,
    cartQuantity,
    gcProductsQuantity,
    isGiftCardOnly,
    isFreeShipping,
    geoLocRegion
  );
  const cartTotal =
    cartAmount + (isGiftCardOnly || isFreeShipping ? 0 : shippingTotal);

  return {
    id: cart?.id,
    customerId: cart?.customer_id,
    cartAmount,
    cartTotal,
    shippingTotal: isGiftCardOnly || isFreeShipping ? 0 : shippingTotal,
    cartQuantity,
    lineItems,
    shippingPromoMessage,
    withSoldOutItem: cart?.with_sold_out_item,
    showOneClickCheckout: cart?.showOneClickCheckout,
    soldOutIDs: cart?.soldOutIDs ?? [],
    withGiftCard: withGiftCard,
    isGiftCardOnly: isGiftCardOnly,
    promotions: cart?.promotions,
    discountAmount: discount_amount,
    totalDiscountAmount: totalDiscount,
    coupons: coupons,
    isFreeShipping,
  };
};
