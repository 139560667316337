import {
  ICheckout,
  IGetAddresses,
  IGetStoredInstruments,
  IProcessPayment,
} from "../../../api/src/services/vino-api-services";
import { ReactNode, useState } from "react";

import OneClickCheckoutContext from "./context";
import { OneClickCheckoutInterface } from "./context";

import { trackOneClickCheckout } from "../../utils/event-tracking";
import useAuth from "../../hooks/use-auth";
import vinoFetch from "../../utils/vinoFetch";

const OneClickCheckoutProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { getAccessToken } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [processMessage, setProcessMessage] = useState("");
  const [error, setError] = useState(null);
  const BASE_URL = "/api";
  const resetState_ = () => {
    setIsProcessing(false);
    setIsBuying(false);
    setProcessMessage("");
    setError(null);
  };

  const ctx: OneClickCheckoutInterface = {
    isProcessing,
    isBuying,
    isRedirecting,
    processMessage,
    error,
    setRedirecting: (state) => {
      setIsRedirecting(state);
    },
    resetState: () => resetState_,

    getAddressesByCustomerId: async ({
      customerID,
    }: IGetAddresses): Promise<any> => {
      setIsProcessing(true);
      const accessToken = await getAccessToken();
      const headers = { Authorization: `Bearer ${accessToken}` };
      const { data } = await vinoFetch.get(
        `${BASE_URL}/get-addresses-by-customerid`,
        {
          headers,
          params: {
            customerID,
          },
        }
      );
      setIsProcessing(false);
      return data;
    },

    getStoredInstrumentsByCustomerId: async ({
      customerID,
      defaultOnly,
    }: IGetStoredInstruments): Promise<any> => {
      setIsProcessing(true);
      const accessToken = await getAccessToken();
      const headers = { Authorization: `Bearer ${accessToken}` };
      const { data } = await vinoFetch.get(
        `${BASE_URL}/get-stored-instruments-by-customerid`,
        {
          headers,
          params: {
            customerID,
            defaultOnly,
          },
        }
      );
      setIsProcessing(false);
      return data;
    },

    doOneClickCheckout: async ({
      customerID,
      market,
      cartId,
      cart,
    }: ICheckout): Promise<any> => {
      setIsProcessing(true);
      setIsBuying(true);
      setProcessMessage("Buying ...");
      trackOneClickCheckout("One Click Checkout", cart, "buying");
      const accessToken = await getAccessToken();
      const headers = { Authorization: `Bearer ${accessToken}` };
      try {
        // PART1
        const part1requestBody = {
          customerID,
          market: market,
          cartId,
        };
        const {
          data: part1Data,
        } = await vinoFetch.post(
          `${BASE_URL}/one-click-checkout-p1`,
          part1requestBody,
          { headers }
        );
        setProcessMessage("Creating the order ...");

        // PART2
        trackOneClickCheckout("One Click Checkout", cart, "preparing");
        const part2requestBody = {
          customerID,
          market: market,
          cartId,
          checkoutOrder: part1Data,
        };
        const {
          data: part2Data,
        } = await vinoFetch.post(
          `${BASE_URL}/one-click-checkout-p2`,
          part2requestBody,
          { headers }
        );
        setProcessMessage("Some final touches ...");

        // FINAL PAYMENT
        trackOneClickCheckout("One Click Checkout", cart, "initiating");
        const { data: finalPaymentData } = await vinoFetch.post(
          `${BASE_URL}/process-payment`,
          part2Data,
          {
            headers,
          }
        );

        setProcessMessage("Almost done! ...");
        trackOneClickCheckout("One Click Checkout", cart, "success");
        return finalPaymentData;
      } catch (error) {
        setProcessMessage("Ooops Error");
        const errorData = error?.response?.data;
        resetState_();
        setError({ error: errorData });
        return { error: errorData };
      }
    },

    processPayment: async (payload: IProcessPayment): Promise<any> => {
      const accessToken = await getAccessToken();
      const headers = { Authorization: `Bearer ${accessToken}` };
      const { data } = await vinoFetch.post(
        `${BASE_URL}/process-payment`,
        payload,
        {
          headers,
        }
      );
      setProcessMessage("Almost done! ...");
      return data;
    },
  };

  return (
    <OneClickCheckoutContext.Provider value={ctx}>
      {children}
    </OneClickCheckoutContext.Provider>
  );
};

export default OneClickCheckoutProvider;
