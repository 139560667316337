import { AuthState, VinoUser, initialAuthState } from "./state";

import { createContext } from "react";

export interface AuthContextInterface extends AuthState {
  join: (options: { returnTo: string }) => Promise<void>;
  login: (options: { returnTo: string }) => Promise<void>;
  logout: () => void;
  getAccessToken: () => Promise<string>;
  validateEmail: (email: string) => Promise<boolean>;
  updateProfile: (payload: {
    given_name: string;
    family_name: string;
    phone: string;
    birthdate: string;
  }) => Promise<{ error?: Error }>;
  changeEmailStepTwo: (payload: {
    email: string;
  }) => Promise<{ error?: Error }>;
  changeEmailStepThree: (payload: {
    email: string;
    otp: string;
  }) => Promise<{ error?: Error }>;
  rePopulateUser: (user: VinoUser) => void;
  checkReferralCode?: (bigcommerceId: string) => void;
  hasSocialLogin: (email: string) => Promise<boolean>;
}

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <AuthProvider>.");
};

const initialContext = {
  ...initialAuthState,
  join: stub,
  login: stub,
  logout: stub,
  getAccessToken: stub,
  validateEmail: stub,
  updateProfile: stub,
  changeEmailStepTwo: stub,
  changeEmailStepThree: stub,
  rePopulateUser: stub,
  checkReferralCode: stub,
  hasSocialLogin: stub,
};

const AuthContext = createContext<AuthContextInterface>(initialContext);

export default AuthContext;
